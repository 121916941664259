import React from 'react'
import Layout from '../components/layout'
import { NewsletterForm } from "../components/newsletter"

const NewsletterPage =  () => {
    return (
        <>
        <Layout>
            <h1>Join the <span className="font-secondary">Unleaving's</span> Community: Subscribe Now!</h1>
            <NewsletterForm />
        </Layout>
        </>
    )
}

export default NewsletterPage